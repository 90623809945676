import Container from 'components/Container'
import { useTranslation } from 'next-i18next'

import * as S from './styles'

const HeroSection = () => {
  const { t } = useTranslation('diff')

  return (
    <S.Section>
      <Container>
        <S.Wrapper>
          <S.ContentWrapper>
            <S.Title>{t('first-section-title')}</S.Title>
            <S.Description>{t('first-section-description')}</S.Description>
            <S.Link
              target="_blank"
              href="https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site"
            >
              {t('first-section-button')}
            </S.Link>
          </S.ContentWrapper>

          <div>
            <S.DashedWrapper>
              <S.DashedDescription>
                {t('first-section-insight')}
              </S.DashedDescription>
            </S.DashedWrapper>

            <S.LogosWrapper>
              <S.AnimatedLogos />
            </S.LogosWrapper>
          </div>
        </S.Wrapper>
      </Container>
    </S.Section>
  )
}

export default HeroSection
