import styled from 'styled-components'

export const Section = styled.section`
  width: 100%;
  background: #fff;
`

export const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;

  .testimony {
    margin-top: 3.2rem;
    margin-bottom: 2.1rem;
    display: flex;
    align-items: center;

    img {
      opacity: 0.3;
    }

    .avatar {
      border: 50%;
      margin: 0 0.8rem;
      opacity: 1;
    }
  }

  .myCarrousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      > p {
        font-size: 1.4rem;
        line-height: 160%;
        font-weight: 400;
        color: rgba(30, 24, 83, 0.64);
        display: block;
        max-width: 56rem;
        text-align: center;

        @media (max-width: 720px) {
          font-size: 1.4rem;
          line-height: 2.24rem;
          text-align: center;
          max-width: 28rem;
          margin: 0 auto;
        }
      }

      .historySuccess {
        font-weight: 700;
        margin-top: 1.6rem;
        font-size: 1.4rem;
        color: #1e1853;
      }

      > strong {
        font-weight: 700;
        font-size: 1.6rem;
        color: #09c3f4;
        display: block;
        margin-top: 3.2rem;
        line-height: 2.6rem;

        @media (max-width: 720px) {
          font-size: 1.6rem;
          line-height: 2.56rem;
        }
      }

      > span {
        margin-top: 0.2rem;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.12rem;
        font-style: italic;
        color: #b8b4cc;
        display: block;

        @media (max-width: 720px) {
          font-size: 1.2rem;
          line-height: 1.92rem;
          max-width: 32rem;
        }
      }
    }
  }
  .carousel.carousel-slider .control-arrow {
    background: #09c3f4;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 45% !important;
  }
  .control-arrow.control.previous {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .control-arrow.control.next {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Content = styled.div`
  height: 60rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 720px) {
    margin-top: 6.4rem;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  @media (max-width: 720px) {
    align-items: start;
  }
`

export const CarrouselWrapper = styled.div`
  @media (max-width: 720px) {
    width: 100%;
  }
`

export const Highlight = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.14rem;
  color: #09c3f4;

  @media (max-width: 720px) {
    font-size: 1.2rem;
    line-height: 1.44rem;
    letter-spacing: 0.12rem;
  }
`
export const Title = styled.div`
  font-size: 3.2rem;
  font-weight: 700;
  color: #1e1853;
  display: block;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 2.88rem;
    text-align: start;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
  gap: 8rem;
  margin-top: 4.8rem;
  padding: 0 1.6rem;

  > button {
    cursor: pointer;
    background: transparent;
    outline: 0;
    border: 0;
  }

  @media (max-width: 720px) {
    margin-bottom: 6.4rem;
    gap: 4rem;
  }
`
