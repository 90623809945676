import styled from 'styled-components'

export const Container = styled.div`
  max-width: 128rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.6rem;
  background: transparent;
`
