import * as S from './styles'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import Button from 'components/Button'
import Link from 'next/link'
import Container from 'components/Container'

const CastAPI = () => {
  const { t } = useTranslation('diff')

  const [riseTexts, setRiseTexts] = useState(false)

  const handleScroll = () => {
    if (window.scrollY >= 2500) {
      setRiseTexts(true)
    } else {
      setRiseTexts(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <Container>
      <S.Wrapper>
        <S.Content>
          <S.LeftSide>
            <Image
              src="/img/api.png"
              width={580}
              height={400}
              objectFit="contain"
              alt={t('i4cast-api-alt')}
            />
          </S.LeftSide>
          <S.RightSide className={riseTexts ? 'active' : ''}>
            <S.Highlight>i4cast API</S.Highlight>
            <S.Title>
              {t('i4cast-api-title-1')}
              <br />
              {t('i4cast-api-title-2')}
            </S.Title>
            <S.MobileImage>
              <Image
                src="/img/api.png"
                width={580}
                height={400}
                objectFit="contain"
                alt={t('i4cast-api-alt')}
              />
            </S.MobileImage>
            <S.Description>{t('i4cast-api-description')}</S.Description>
            <S.ButtonWrapper>
              <Link href="/i4castapi">
                <Button bigger={false} footer={false} outline>
                  {t('more')}
                </Button>
              </Link>
            </S.ButtonWrapper>
          </S.RightSide>
        </S.Content>
      </S.Wrapper>
    </Container>
  )
}

export default CastAPI
