import styled, { keyframes } from 'styled-components'

const appearFromBelow = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 14rem;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: -8.6rem;
  }
`

export const LeftSide = styled.div`
  @media (min-width: 720px) {
    &.active {
      animation: ${appearFromBelow} 1s linear;
      opacity: 1;
    }
  }
`

export const Highlight = styled.span`
  display: block;
  color: #09c3f4;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 0.16rem;
  text-transform: uppercase;

  @media (max-width: 720px) {
    font-size: 1.4rem;
    line-height: 1.68rem;
  }
`
export const Title = styled.strong`
  display: block;
  color: #1e1853;
  font-weight: 700;
  max-width: 52rem;
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin: 1.6rem 0 2.4rem;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 2.88rem;
  }
`
export const Description = styled.p`
  display: block;
  color: rgba(30, 24, 83, 0.64);
  font-weight: 400;
  max-width: 520px;
  font-size: 1.4rem;
  line-height: 2.6rem;

  @media (max-width: 720px) {
    font-size: 1.4rem;
    line-height: 2.24rem;
    margin-top: 2.4rem;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 4.8rem;

  @media (max-width: 720px) {
    button {
      width: 100%;
    }
  }
`

export const RightSide = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`

export const MobileImage = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: unset;
  }
`
