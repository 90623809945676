import HeroSection from '../components/HeroSection'
import CastPlatform from '../components/CastPlatform'
import CastAPI from '../components/CastAPI'
import HistoricData from '../components/HistoricData'
import WhySection from '../components/WhySection'
import Testimonials from '../components/Testimonials'
import BlogContentItems from '../components/BlogContentItems'
import { PostPropsAdvantages } from 'pages'
import { ReactNode } from 'react'
import CallToAction from '../components/CallToAction'
import AdvancedStrategies from '../components/AdvancedStrategies'

export const getComponentOrder = ({
  articles,
}: PostPropsAdvantages): { component: ReactNode }[] => [
  {
    component: <HeroSection />,
  },
  {
    component: <WhySection />,
  },
  {
    component: <Testimonials />,
  },
  {
    component: <CallToAction />,
  },
  {
    component: <CastPlatform />,
  },
  {
    component: <CastAPI />,
  },
  {
    component: <HistoricData />,
  },
  {
    component: <AdvancedStrategies />,
  },

  {
    component: <BlogContentItems articles={articles} />,
  },
]
