import styled from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
  /* padding: 0 14rem; */
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12.8rem;
  /* margin-bottom: 24rem;
  margin-top: 12rem; */

  @media (max-width: 720px) {
    /* padding: 0rem 3.2rem; */
  }
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  align-items: center;
`
export const Title = styled.h1`
  max-width: 64rem;
  font-weight: bold;
  font-size: 3.8rem;
  color: #1ec3fc;
  min-width: 80rem;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 3rem;
    min-width: 0rem;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16rem;

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 6.4rem;
    justify-content: center;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
`
export const IndexOfCard = styled.h1`
  max-width: 64rem;
  font-weight: bold;
  font-size: 5rem;
  color: #1ec3fc;
  text-align: center;

  @media (max-width: 720px) {
    max-width: 31.2rem;
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
`
export const ContentOfCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  text-align: center;
`

export const TitleOfCard = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #1f1852;
`
export const DescriptionOfCard = styled.p`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #6b6b92;
`

export const ButtonWrapper = styled.div`
  @media (max-width: 720px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`
