import Button from 'components/Button'
import Container from 'components/Container'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import * as S from './styes'

const AdvancedStrategies = () => {
  const { t } = useTranslation('diff')

  const router = useRouter()

  const formDemoLink =
    router.locale === 'en-us'
      ? 'https://lp.i4sea.com/guides'
      : 'http://lp.i4sea.com/pt-br/guias'

  return (
    <Container>
      <S.Wrapper>
        <S.Content>
          <S.Column>
            <S.Title>{t('advanced-strategies-first-title')}</S.Title>
            <S.CardsWrapper>
              <S.Card>
                <S.IndexOfCard>
                  {t('advanced-strategies-card-1-index')}
                </S.IndexOfCard>
                <S.ContentOfCard>
                  <S.TitleOfCard>
                    {t('advanced-strategies-card-1-title')}
                  </S.TitleOfCard>
                  <S.DescriptionOfCard>
                    {t('advanced-strategies-card-1-description')}
                  </S.DescriptionOfCard>
                </S.ContentOfCard>
              </S.Card>
              <S.Card>
                <S.IndexOfCard>
                  {t('advanced-strategies-card-2-index')}
                </S.IndexOfCard>
                <S.ContentOfCard>
                  <S.TitleOfCard>
                    {t('advanced-strategies-card-2-title')}
                  </S.TitleOfCard>
                  <S.DescriptionOfCard>
                    {t('advanced-strategies-card-2-description')}
                  </S.DescriptionOfCard>
                </S.ContentOfCard>
              </S.Card>
              <S.Card>
                <S.IndexOfCard>
                  {t('advanced-strategies-card-3-index')}
                </S.IndexOfCard>
                <S.ContentOfCard>
                  <S.TitleOfCard>
                    {t('advanced-strategies-card-3-title')}
                  </S.TitleOfCard>
                  <S.DescriptionOfCard>
                    {t('advanced-strategies-card-3-description')}
                  </S.DescriptionOfCard>
                </S.ContentOfCard>
              </S.Card>
            </S.CardsWrapper>
          </S.Column>
          <S.Column>
            <S.Title>{t('advanced-strategies-second-title')}</S.Title>
            <S.ButtonWrapper>
              <a href={formDemoLink} target="_blank" rel="noreferrer">
                <Button bigger={false} footer={false} outline>
                  {t('more')}
                </Button>
              </a>
            </S.ButtonWrapper>
          </S.Column>
        </S.Content>
      </S.Wrapper>
    </Container>
  )
}

export default AdvancedStrategies
