import { GetStaticProps } from 'next'
import Head from 'next/head'

import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Home from 'views/Home'

import { PostCategory } from 'dto/Post'

import { getLastThreeArticles } from '../../services/post'

export interface PostPropsAdvantages {
  articles: PostCategory[]
}

const PageHome = ({ articles }: PostPropsAdvantages) => {
  const { t } = useTranslation('diff')

  return (
    <div>
      <Head>
        <title>{t('title')}</title>
        <meta name="description" content={t('content-description')} />
      </Head>

      <Home articles={articles} />
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const articles = await getLastThreeArticles(locale)

  return {
    props: {
      ...(await serverSideTranslations(locale ? locale : 'en-us', [
        'comman',
        'diff',
      ])),
      articles,
    },
    revalidate: 5,
  }
}

export default PageHome
