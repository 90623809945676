import * as S from './styles'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import Button from 'components/Button'
import Container from 'components/Container'

const HistoricData = () => {
  const { t } = useTranslation('diff')

  const [riseTexts, setRiseTexts] = useState(false)

  const handleScroll = () => {
    if (window.scrollY >= 3100) {
      setRiseTexts(true)
    } else {
      setRiseTexts(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <Container>
      <S.Wrapper>
        <S.Content>
          <S.LeftSide className={riseTexts ? 'active' : ''}>
            <S.Highlight>{t('historic-data-sub-title')}</S.Highlight>
            <S.Title>{t('historic-data-title')}</S.Title>
            <S.MobileImage>
              <Image
                src="/img/data.webp"
                width={580}
                height={400}
                objectFit="contain"
                alt={t('historic-data-image-alt')}
              />
            </S.MobileImage>
            <S.Description>{t('historic-data-description')}</S.Description>
            <S.ButtonWrapper>
              <Link href="/data">
                <Button bigger={false} footer={false} outline>
                  {t('more')}
                </Button>
              </Link>
            </S.ButtonWrapper>
          </S.LeftSide>
          <S.RightSide>
            <Image
              src="/img/data.webp"
              width={580}
              height={400}
              objectFit="contain"
              alt={t('historic-data-image-alt')}
            />
          </S.RightSide>
        </S.Content>
      </S.Wrapper>
    </Container>
  )
}

export default HistoricData
