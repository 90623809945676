import Container from 'components/Container'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { PostPropsAdvantages } from 'pages'
import { useEffect, useState } from 'react'

import * as S from './styles'

export default function BlogContentItems({ articles }: PostPropsAdvantages) {
  const { t } = useTranslation('diff')

  const [getWidth, setGetWidth] = useState(0)
  useEffect(() => {
    const width = screen.width
    setGetWidth(width)
  }, [])

  return (
    <Container>
      <S.BlogContentItemsWrapper>
        <S.BlogContentHeader>
          <S.BlogContentHighlight>
            {t('home-our-content')}
          </S.BlogContentHighlight>
          <S.BlogContentHighlight2>
            {t('home-our-content-2')}
          </S.BlogContentHighlight2>
        </S.BlogContentHeader>
        <S.BlogContentItems>
          {articles.map((article) => (
            <Link key={article.id} href={`/blog/${article.slug}`}>
              <S.BlogContentItem>
                <Image
                  src={
                    article.image.length ? article.image[1].url : '/i4sea.jpg'
                  }
                  width={getWidth > 720 ? 380 : 312}
                  height={getWidth > 720 ? 200 : 164}
                  objectFit="cover"
                  alt={article.title}
                />
                <S.BlogContentItemInfo>
                  <S.BlogContentItemCategory>
                    {article.categoryName}
                  </S.BlogContentItemCategory>
                  <S.BlogContentItemDescription>
                    {article.title}
                  </S.BlogContentItemDescription>
                </S.BlogContentItemInfo>
              </S.BlogContentItem>
            </Link>
          ))}
        </S.BlogContentItems>
      </S.BlogContentItemsWrapper>
    </Container>
  )
}
