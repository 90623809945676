import Footer from 'components/Footer'
import Menu from 'components/Menu'
import { ReactNode } from 'react'

type BaseProps = {
  children: ReactNode
}

export default function Base({ children }: BaseProps) {
  return (
    <div>
      <Menu />
      <main>{children}</main>
      <Footer />
    </div>
  )
}
