import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import * as S from './styles'
import { useEffect, useState } from 'react'
import Container from 'components/Container'
import { useRouter } from 'next/router'

const WhySection = () => {
  const { t } = useTranslation('diff')
  const [getWidth, setGetWidth] = useState(0)
  const router = useRouter()
  useEffect(() => {
    const width = screen.width
    setGetWidth(width)
  }, [])

  const forPortOperationsLink =
    router.locale === 'en-us'
      ? 'https://lp.i4sea.com/3-steps-to-ensure-increased-productivity-at-terminals'
      : 'https://lp.i4sea.com/pt/3-passos-terminais-1'

  const forVesselsLink =
    router.locale === 'en-us'
      ? 'http://lp.i4sea.com/3-steps-to-optimise-bunker-consumption-between-stops'
      : 'http://lp.i4sea.com/pt-br/3-passos-para-otimizar-o-consumo-de-bunker-entre-escalas'

  const forRenewableEnergyLink =
    router.locale === 'en-us'
      ? 'http://lp.i4sea.com/3-steps-to-reduce-o-m-expenses-in-offshore-wind-farms'
      : 'http://lp.i4sea.com/pt-br/3-steps-to-reduce-o-m-expenses-in-offshore-wind-farms'

  return (
    <S.Section>
      <Container>
        <S.Wrapper id="advantages">
          <S.Header>
            <S.Subtitle>{t('why-section-sub-title')}</S.Subtitle>
            <S.Title>{t('why-section-title')}</S.Title>
          </S.Header>

          <S.AdvantagesFirstSection>
            <S.WrapperAdvantageIcon>
              <S.AdvantageIconHeader>
                <Image
                  objectFit="cover"
                  width={getWidth > 720 ? 64 : 48}
                  height={getWidth > 720 ? 64 : 48}
                  src="/icons/map.png"
                  alt={t('why-section-option-1-alt')}
                />
                <S.AdvantageTitle>
                  {t('why-section-option-1-title')}
                </S.AdvantageTitle>
              </S.AdvantageIconHeader>
              <S.AdvantageIconContent>
                <S.AdvantageDescription>
                  {t('why-section-option-1-description')}
                </S.AdvantageDescription>

                <S.CardLink
                  target="_blank"
                  href={forPortOperationsLink}
                  rel="noreferrer"
                >
                  {t('why-section-button')}
                </S.CardLink>
              </S.AdvantageIconContent>
            </S.WrapperAdvantageIcon>
            <S.WrapperAdvantageIcon>
              <S.AdvantageIconHeader>
                <Image
                  objectFit="cover"
                  width={getWidth > 720 ? 64 : 48}
                  height={getWidth > 720 ? 64 : 48}
                  src="/icons/ship.png"
                  alt={t('why-section-option-1-alt')}
                />
                <S.AdvantageTitle>
                  {t('why-section-option-3-title')}
                </S.AdvantageTitle>
              </S.AdvantageIconHeader>
              <S.AdvantageIconContent>
                <S.AdvantageDescription>
                  {t('why-section-option-3-description')}
                </S.AdvantageDescription>
                <S.CardLink
                  target="_blank"
                  href={forVesselsLink}
                  rel="noreferrer"
                >
                  {t('why-section-button')}
                </S.CardLink>
              </S.AdvantageIconContent>
            </S.WrapperAdvantageIcon>
            <S.WrapperAdvantageIcon>
              <S.AdvantageIconHeader>
                <Image
                  objectFit="cover"
                  width={getWidth > 720 ? 64 : 48}
                  height={getWidth > 720 ? 64 : 48}
                  src="/icons/energy.png"
                  alt={t('why-section-option-4-alt')}
                />
                <S.AdvantageTitle>
                  {t('why-section-option-4-title')}
                </S.AdvantageTitle>
              </S.AdvantageIconHeader>
              <S.AdvantageIconContent>
                <S.AdvantageDescription>
                  {t('why-section-option-4-description')}
                </S.AdvantageDescription>
                <S.CardLink
                  target="_blank"
                  href={forRenewableEnergyLink}
                  rel="noreferrer"
                >
                  {t('why-section-button')}
                </S.CardLink>
              </S.AdvantageIconContent>
            </S.WrapperAdvantageIcon>
          </S.AdvantagesFirstSection>
          {/* <S.AdvantagesSecondSection>
          </S.AdvantagesSecondSection> */}
        </S.Wrapper>
      </Container>
    </S.Section>
  )
}

export default WhySection
