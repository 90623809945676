import Image from 'next/image'
import * as S from './styles'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Carousel } from 'react-responsive-carousel'
import { useRouter } from 'next/router'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Container from 'components/Container'

const testimonials = [
  {
    id: 1,
    description:
      'We acquired i4cast® in an effort to increase safety and reliability. It is helping to minimise ocean and weather uncertainties through hyperlocal forecasts, real-time data analysis and correlations of environmental conditions during operations.',
    author: 'Luciano Leite',
    position: 'Operations Manager at Wilson Sons Terminais Salvador',
    descricao:
      'Adquirimos o i4cast visando prover maior segurança e confiabilidade, minimizando incertezas meteorológicas e oceanográficas através de previsões, análises e cruzamento de dados em tempo real das condições ambientais no momento das manobras.',
    posicao: 'Gerente de Operações na Wilson Sons Terminais Salvador',
  },
  {
    id: 2,
    description:
      'The system developed by i4sea will be an important ally in optimising Açu Petróleo’s operation planning, ensuring the full usage of our capacity, a constant interaction with the other stakeholders of Açu Port, and mainly the safety of ship manoeuvres at our port terminal.',
    author: 'Mauricio Zannin',
    position: 'Operations Director at Açu Petróleo',
    descricao:
      'O sistema desenvolvido pela i4sea será um importante aliado na otimização da programação do terminal de petróleo da Açu Petróleo (T-Oil), garantindo o pleno uso da nossa capacidade, uma interação constante com os demais atores do Porto do Açu, e principalmente a segurança das manobras dos navios que operam no T-Oil.',
    posicao: 'Diretor de Operações na Açu Petróleo',
  },
  {
    id: 3,
    description:
      'As a result, using i4cast®’s hyperlocal forecasts and actionable insights, Porto do Açu significantly reduced restrictions during operations due to bad weather and ocean conditions. i4sea forecasts at Porto do Açu are proven to be twice as accurate as generic forecasts, with up to 94% accuracy in decision making related to the impact of the ocean and weather on port operations.',
    author: 'Mario Rodrigues',
    position: 'Maritime Operation Manager',
    descricao:
      'Como resultado, usando as previsões hiperlocais e insights acionáveis do i4cast®, o Porto do Açu reduziu significativamente a possibilidade de restrições durante as operações devido ao mau tempo e às condições do oceano. As previsões da i4sea no Porto do Açu são comprovadamente duas vezes mais precisas que as previsões genéricas, com até 94% de acerto na tomada de decisão relacionada ao impacto do oceano e do clima nas operações portuárias',
    posicao: 'Gerente de operações marítimas',
  },
  {
    id: 4,
    description:
      'The most accurate forecast for the port area and navigability.',
    author: 'Leandro Chies',
    position: 'Operations Supervisor at Wilson Sons',
    descricao:
      'A previsão mais assertiva para a localidade do porto e da navegabilidade.',
    posicao: 'Supervisor de operações no Wilson Sons',
  },
  {
    id: 5,
    description:
      'Safety and reliability, especially with the operation of larger vessels. The ships are arriving late from Europe, and the accuracy in the departure time of the ships is very important. This provides confidence to the shipowners during this period of omission from other ports and movement at the Port of Santos.',
    author: 'Evelyn Lima',
    position: 'Executive Manager of Port Operations Planning at Santos Brasil',
    descricao:
      'Segurança e confiabilidade. Principalmente com a operação de navios maiores. Os navios estão vindo atrasados da Europa e a assertividade no tempo de saída dos navios é muito importante. Isso passa segurança para os armadores nesse período de omissão de outros portos e movimentação no porto de Santos.',
    posicao: 'Planejamento operacional Santos Brasil',
  },
  {
    id: 6,
    description:
      'It greatly facilitated decision-making with the GNA and operations personnel. Now I can predict environmental conditions, and they materialise over time.',
    author: 'Renan Fegalo',
    position: 'MARINE PROCESS ENGINEER - AB Klaipėdos nafta',
    descricao:
      'Facilitou muito a tomada de decisão com o pessoal da GNA e de operação. Agora eu consigo prever as condições ambientais e elas se concretizam com o passar do tempo.',
    posicao: 'Engenheiro de Processos Marítimos - AB Klaipėdos Nafta',
  },
  {
    id: 7,
    description:
      'Today I have easy and very quick access to much more reliable information about local metocean conditions. This gives me greater operational safety and allows me to predict and manage risk situations.',
    author: 'André Ribeiro',
    position: 'Risk, Emergency, and QSMS Coordinator at Porto do Açu',
    descricao:
      'Hoje eu consigo ter acesso fácil e muito rápido de informações muito mais confiáveis sobre as condições meteoceanográficas locais. Isso me dá uma maior segurança operacional e faz com que eu consiga prever e gerenciar situações de risco.',
    posicao: 'Coordenador de Riscos, Emergências e QSMS no Porto do Açu',
  },
]

const logos = [
  {
    img: 'wilsons-sons',
    img2: 'wilsons-sons2',
    value: 1,
  },
  {
    img: 'vast',
    img2: 'vast2',
    value: 2,
  },
  {
    img: 'porto-acu',
    img2: 'porto-acu2',
    value: 3,
  },
  {
    img: 'wilsons-sons',
    img2: 'wilsons-sons2',
    value: 4,
  },
  {
    img: 'santos',
    img2: 'santos2',
    value: 5,
  },
  {
    img: 'KN',
    img2: 'KN2',
    value: 6,
  },
  {
    img: 'porto-acu',
    img2: 'porto-acu2',
    value: 7,
  },
]

const Testimonials = () => {
  const { t } = useTranslation('diff')
  const router = useRouter()
  const [isSelected, setIsSelected] = useState(0)

  function handleSelected(index: number) {
    setIsSelected(index)
  }

  return (
    <S.Section>
      <Container>
        <S.Wrapper>
          <S.Content>
            <S.Header>
              <S.Highlight>{t('testimonials-title')}</S.Highlight>
              <S.Title>{t('testimonials-description')}</S.Title>
            </S.Header>

            <S.CarrouselWrapper>
              <Carousel
                showIndicators={false}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={6100}
                onChange={(index) => {
                  setIsSelected(index)
                }}
                selectedItem={isSelected}
              >
                {testimonials.map((testimony) => (
                  <div key={testimony.id} className="myCarrousel">
                    <div className="testimony">
                      <Image
                        src="/icons/quotes1.png"
                        width={24}
                        height={16}
                        alt="Aspas"
                      />
                      <div className="avatar">
                        <Image
                          className="avatar"
                          src="/icons/avatar.svg"
                          width={54}
                          height={54}
                          alt="Imagem do cliente"
                        />
                      </div>
                      <Image
                        src="/icons/quotes2.png"
                        width={24}
                        height={16}
                        alt="Aspas"
                      />
                    </div>
                    <div>
                      <p>
                        {router.locale === 'en-us'
                          ? testimony.description
                          : testimony.descricao}
                      </p>
                      <strong>{testimony.author}</strong>
                      <span>
                        {router.locale === 'en-us'
                          ? testimony.position
                          : testimony.posicao}
                      </span>
                      {/*
                <p className="historySuccess">
                  Leia a história de sucesso &gt;
                </p>
                */}
                    </div>
                  </div>
                ))}
              </Carousel>
            </S.CarrouselWrapper>

            <S.LogosWrapper>
              {logos.map((logo, index) => (
                <button key={logo.value} onClick={() => handleSelected(index)}>
                  <Image
                    objectFit="contain"
                    width={104}
                    height={48}
                    quality={75}
                    src={
                      isSelected === index
                        ? `/${logo.img2}.png`
                        : `/${logo.img}.png`
                    }
                    alt="Marcas"
                  />
                </button>
              ))}
            </S.LogosWrapper>
          </S.Content>
        </S.Wrapper>
      </Container>
    </S.Section>
  )
}

export default Testimonials
