import styled, { keyframes } from 'styled-components'

const brandsAnimation = keyframes`
  from {
    will-change: transform;
  transform: translate3d(0px, 0px, 0px);
  }
  to {
  will-change: transform;
  transform: translate3d(-1698.5px, 0px, 0px);
  }
`

const brandsAnimation1 = keyframes`
  from {
    will-change: transform;
  transform: translate3d(-375px, 0px, 0px);
  }
  to {
  will-change: transform;
  transform: translate3d(-1650.5px, 0px, 0px);
  }
`

export const Section = styled.section`
  background-image: url('/i4sea-sea-port-view-by-night.webp');
  background-size: cover;
  background-position: center bottom;

  @media (max-width: 720px) {
    background-size: cover;
  }
`

export const Wrapper = styled.section`
  width: 100%;
  height: 120dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 720px) {
    height: 80rem;
    width: 100%;
    align-items: start;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (max-width: 720px) {
    width: 100%;
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2rem;
  letter-spacing: 0.14rem;
  background: linear-gradient(
    0deg,
    #0b63af 0.05%,
    #147cc0 18.93%,
    #1894d2 37.92%,
    #1fa6df 55.21%,
    #23b2e7 70.02%,
    #25b6ea 80.63%
  );
  border-radius: 0.8rem;
  border: 0;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      0deg,
      #095596 0.05%,
      #116ba6 18.93%,
      #1581b8 37.92%,
      #1b92c4 55.21%,
      #1f9fcf 70.02%,
      #21a3d1 80.63%
    );
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`

export const Title = styled.h1`
  display: block;
  max-width: 64rem;
  font-weight: bold;
  font-size: 4rem;
  color: #fff;
  text-align: center;

  @media (max-width: 720px) {
    max-width: 31.2rem;
    font-size: 2.8rem;
    line-height: 3.4rem;
    margin-top: 12rem;
  }
`
export const Description = styled.p`
  display: block;
  max-width: 54rem;
  font-size: 1.8rem;
  line-height: 2.88rem;
  color: #fff;
  text-align: center;

  @media (max-width: 720px) {
    max-width: 29rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: 0rem;
  }
`

export const DashedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 720px) {
    margin-top: 7rem;
  }
`

export const DashedDescription = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 0.16rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.1rem;
  opacity: 0.64;

  @media (max-width: 720px) {
    margin-top: -2rem;
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: block;
    max-width: 36rem;
  }
`

export const LogosWrapper = styled.div`
  width: 80rem;
  height: 6.4rem;
  margin-top: 3.2rem;
  overflow: hidden;
  position: relative;
  max-width: 80rem;

  @media (max-width: 720px) {
    margin-top: 1.6rem;
    width: 100%;
    height: 4.8rem;
  }
`

export const AnimatedLogos = styled.div`
  display: flex;
  justify-content: center;
  width: 339.7rem;
  height: 6.4rem;
  background: url('/logo_brands.png') no-repeat center bottom;
  background-size: contain;
  position: absolute;
  z-index: 1;
  animation: ${brandsAnimation} linear infinite 45s;

  @media (max-width: 720px) {
    height: 4.8rem;
    animation: ${brandsAnimation1} linear infinite 45s;
  }
`
