import { Fragment } from 'react'

import Base from 'templates/Base'

import { PostPropsAdvantages } from 'pages'

import { getComponentOrder } from './utils/getComponentsOrder'

export default function Home({ articles }: PostPropsAdvantages) {
  const componentsOrder = getComponentOrder({ articles })
  return (
    <Base>
      {componentsOrder.map((item, index) => {
        return <Fragment key={index}>{item.component}</Fragment>
      })}
    </Base>
  )
}
