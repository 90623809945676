import styled from 'styled-components'

export const Section = styled.section`
  width: 100%;
  background: rgba(9, 195, 244, 0.04);
`

export const Wrapper = styled.section`
  width: 100%;
  padding: 14rem 0 12rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 720px) {
    padding: 12rem 0 4.8rem 0;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    gap: 1.6rem;
    align-items: start;
  }
`

export const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  color: #1e1853;

  @media (max-width: 720px) {
    /* margin-top: -6.4rem; */
    font-size: 1.2rem;
    letter-spacing: 0.12rem;
  }
`

export const Title = styled.h3`
  font-weight: 700;
  font-size: 3.8rem;
  line-height: 4.6rem;
  color: #09c3f4;
  max-width: 800px;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
`

export const AdvantagesFirstSection = styled.div`
  display: flex;
  margin-top: 5.6rem;
  gap: 12rem;
  align-self: center;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    width: 100%;
  }
`

export const AdvantagesSecondSection = styled.div`
  display: flex;
  margin-top: 6.8rem;
  gap: 18.2rem;
  align-self: center;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    margin-top: 4.8rem;
    width: 100%;
  }
`

export const WrapperAdvantageIcon = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
`

export const AdvantageTitle = styled.strong`
  font-weight: 700;
  font-size: 1.8rem;
  color: #1e1853;
  display: block;

  @media (max-width: 720px) {
    font-size: 1.6rem;
  }
`

export const CardLink = styled.a`
  display: flex;
  justify-content: center;
  align-self: start;
  padding: 1.2rem 1.8rem;
  letter-spacing: 0.14rem;
  background: none;
  border-radius: 0.8rem;
  border: 1px solid #1894d2;
  color: #1894d2;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    border-color: #1581b8;
  }
  @media (max-width: 720px) {
    width: 100%;
    align-items: center;
  }
`

export const AdvantageDescription = styled.span`
  font-size: 1.4rem;
  color: rgba(30, 24, 83, 0.64);
  line-height: 2.4rem;
  text-align: start;
  max-width: 30rem;

  @media (max-width: 720px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
    margin-top: -0.8rem;
    text-align: center;
  }
`
export const AdvantageIconHeader = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  align-self: center;
`

export const AdvantageIconContent = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: start;
  align-self: start;

  @media (max-width: 720px) {
    align-items: center;
    width: 100%;
  }
`
