import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
  padding-top: 14rem;

  @media (max-width: 720px) {
    padding-top: 0;
    margin-top: 6.4rem;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`

export const LeftSide = styled.div``

export const Highlight = styled.span`
  display: block;
  color: #09c3f4;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 0.16rem;
  text-transform: uppercase;

  @media (max-width: 720px) {
    font-size: 1.4rem;
    line-height: 1.68rem;
  }
`
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  margin-top: 6.4rem;

  @media (max-width: 720px) {
    display: none;
  }
`
export const Icon = styled.img`
  margin-right: 1.6rem;
  width: 1.8rem;
  height: 1.8rem;
`
export const Button = styled.button`
  color: rgba(30, 24, 83, 0.16);
  line-height: 2.9rem;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
  border: 0;
  background: transparent;
  transition: 0.2s all;
  cursor: pointer;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(30, 24, 83, 0.08);
  margin-left: 4.8rem;
  &.active {
    color: #09c3f4;
  }
`
export const Title = styled.strong`
  display: block;
  color: #1e1853;
  font-weight: 700;
  max-width: 520px;
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin: 1.6rem 0 2.4rem;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 2.88rem;
  }
`
export const Description = styled.p`
  display: block;
  color: rgba(30, 24, 83, 0.64);
  font-weight: 400;
  max-width: 520px;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-top: 2.4rem;
  & + p {
    margin-top: 3.2rem;
  }

  @media (max-width: 720px) {
    font-size: 1.4rem;
    line-height: 2.24rem;
    margin-top: 2.4rem;
  }
`

export const Dropdown = styled.select`
  display: none;
  color: #09c3f4;

  @media (max-width: 720px) {
    margin-top: 2.4rem;
    display: unset;
    width: 100%;
    height: 5.6rem;
    border-radius: 0.8rem;
    border: 0;
    outline: 0;
    background: url('icons/arrow.png') no-repeat 92.5% 50%
      rgba(9, 195, 244, 0.08);
    padding: 0 2.4rem;
    font-size: 1.8rem;
    line-height: 2.16rem;
    font-weight: 700;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`

export const DropdownItem = styled.option`
  display: none;

  @media (max-width: 720px) {
    display: unset;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.16rem;
    letter-spacing: 0.018rem;
    color: #09c3f4;
    background-color: rgba(9, 195, 244, 0.08);
  }
`

const appearFromBelow = keyframes`
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const screenAnimation = keyframes`
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
    transform: scale(1.05);
  }
`

export const ComputerWrapper = styled.div`
  position: relative;

  @media (max-width: 720px) {
    margin-top: -17.6rem;
  }
`

export const ComputerAniation = styled.div`
  position: absolute;
  top: 37%;
  left: 15%;
  width: 471px;
  height: 48px;
  visibility: hidden;

  @media (max-width: 720px) {
    width: 29.2rem;
    height: 29rem;
  }

  .active {
    animation: ${screenAnimation} 1s ease-in-out;
    animation-delay: 1s;
    visibility: visible;
    transform: scale(1.05);
    transition: 1s all;
  }
`

export const RightSide = styled.div``

export const AnimatedContent = styled.div`
  @media (min-width: 720px) {
    animation: ${appearFromBelow} 1s linear;
  }
`

export const RightSideDescription = styled.p`
  display: block;
  color: rgba(30, 24, 83, 0.64);
  font-weight: 400;
  max-width: 520px;
  font-size: 1.4rem;
  line-height: 2.6rem;
  margin-top: 2.4rem;
  text-align: center;

  @media (max-width: 720px) {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.92rem;
    color: rgba(30, 24, 83, 0.64);
  }
`

export const ButtonsWrapper = styled.div`
  margin-top: 4.8rem;
  display: flex;
  justify-content: center;
  gap: 1.6rem;

  @media (max-width: 720px) {
    button {
      width: 100%;
    }
    flex-direction: column;
  }
`
