import styled from 'styled-components'

export const BlogContentItemsWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24rem;
  margin-top: 12rem;

  @media (max-width: 720px) {
    margin-bottom: 14rem;
  }
`

export const BlogContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const BlogContentHighlight = styled.h3`
  color: #09c3f4;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.14rem;
  text-transform: uppercase;

  @media (max-width: 720px) {
    font-size: 1.2rem;
  }
`

export const BlogContentHighlight2 = styled.h3`
  color: #1e1853;
  font-weight: 700;
  font-size: 3.2rem;
  margin-top: 1.6rem;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 2.88rem;
    max-width: 31.2rem;
  }
`

export const BlogContentItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    gap: 8rem;
  }
`

export const BlogContentItem = styled.div`
  margin-top: 4.8rem;
  cursor: pointer;
  height: 20rem;

  img {
    border-radius: 16px;
  }
`

export const BlogContentItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const BlogContentItemCategory = styled.h3`
  color: #09c3f4;
  font-weight: 700;
  font-size: 1.2rem;
  max-width: 18rem;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  margin-top: 2.2rem;

  @media (max-width: 720px) {
    font-size: 1.1rem;
  }
`

export const BlogContentItemDescription = styled.h4`
  color: #1e1853;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
  max-width: 34.8rem;
  margin-top: 0.8rem;

  @media (max-width: 720px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    max-width: 29.6rem;
  }
`
