import Container from 'components/Container'
import { useTranslation } from 'next-i18next'
import * as S from './styles'

const CallToAction = () => {
  const { t } = useTranslation('diff')

  return (
    <Container>
      <S.Wrapper>
        <S.Title>{t('call-to-action-title')}</S.Title>

        <S.Link
          target="_blank"
          href="https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site"
          rel="noreferrer"
        >
          {t('call-to-action-button')}
        </S.Link>
      </S.Wrapper>
    </Container>
  )
}

export default CallToAction
