import { useEffect, useState } from 'react'
import * as S from './styles'
import Image from 'next/image'
import Button from 'components/Button'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Container from 'components/Container'

const CastPlatform = () => {
  const [isActive, setIsActive] = useState('first')
  const { t } = useTranslation('diff')
  const [getWidth, setGetWidth] = useState(0)

  const formDemoLink =
    'https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site'

  useEffect(() => {
    const width = screen.width
    setGetWidth(width)
  }, [])

  return (
    <Container>
      <S.Wrapper id="product">
        <S.Content>
          <S.LeftSide>
            <S.Highlight>{t('cast-platform-sub-title')}</S.Highlight>
            <S.Title>{t('cast-platform-title')}</S.Title>
            <S.Description>{t('cast-platform-description')}</S.Description>
            <S.Dropdown
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <S.DropdownItem value="first">
                {t('cast-platform-option-1')}
              </S.DropdownItem>
              <S.DropdownItem value="second">
                {t('cast-platform-option-2')}
              </S.DropdownItem>
              <S.DropdownItem value="third">
                {t('cast-platform-option-3')}
              </S.DropdownItem>
              <S.DropdownItem value="fourth">
                {t('cast-platform-option-4')}
              </S.DropdownItem>
            </S.Dropdown>
            <S.Buttons>
              <S.Button
                onClick={() => setIsActive('first')}
                className={isActive === 'first' ? 'active' : ''}
              >
                {isActive === 'first' && (
                  <S.Icon
                    src="/icons/play.svg"
                    alt={t('cast-platform-alt-play-button')}
                  />
                )}
                {t('cast-platform-option-1')}
              </S.Button>
              <S.Button
                onClick={() => setIsActive('second')}
                className={isActive === 'second' ? 'active' : ''}
              >
                {isActive === 'second' && (
                  <S.Icon
                    src="/icons/play.svg"
                    alt={t('cast-platform-alt-play-button')}
                  />
                )}
                {t('cast-platform-option-2')}
              </S.Button>
              <S.Button
                onClick={() => setIsActive('third')}
                className={isActive === 'third' ? 'active' : ''}
              >
                {isActive === 'third' && (
                  <S.Icon
                    src="/icons/play.svg"
                    alt={t('cast-platform-alt-play-button')}
                  />
                )}
                {t('cast-platform-option-3')}
              </S.Button>
              <S.Button
                onClick={() => setIsActive('fourth')}
                className={isActive === 'fourth' ? 'active' : ''}
              >
                {isActive === 'fourth' && (
                  <S.Icon
                    src="/icons/play.svg"
                    alt={t('cast-platform-alt-play-button')}
                  />
                )}
                {t('cast-platform-option-4')}
              </S.Button>
            </S.Buttons>
          </S.LeftSide>
          {isActive === 'first' && (
            <S.RightSide>
              <S.ComputerWrapper>
                <Image
                  loading="eager"
                  src="/img/p3_02.svg"
                  width={getWidth > 720 ? 580 : 330}
                  height={getWidth > 720 ? 480 : 231}
                  objectFit="contain"
                  alt={t('i4cast-api-alt')}
                />
              </S.ComputerWrapper>
              <S.AnimatedContent>
                <S.RightSideDescription>
                  {t('cast-platform-option-1-description')}
                </S.RightSideDescription>
                <S.ButtonsWrapper>
                  <Link href="/i4castproduct">
                    <Button outline>{t('more')}</Button>
                  </Link>
                  <a href={formDemoLink} target="_blank" rel="noreferrer">
                    <Button className="getdemo" bigger>
                      {t('cast-platform-talk-button')}
                    </Button>
                  </a>
                </S.ButtonsWrapper>
              </S.AnimatedContent>
            </S.RightSide>
          )}
          {isActive === 'second' && (
            <S.RightSide>
              <S.ComputerWrapper>
                <Image
                  src="/img/p2.svg"
                  width={getWidth > 720 ? 580 : 330}
                  height={getWidth > 720 ? 480 : 231}
                  objectFit="contain"
                  alt={t('i4cast-api-alt')}
                />
                <S.AnimatedContent>
                  <S.RightSideDescription>
                    {t('cast-platform-option-2-description')}
                  </S.RightSideDescription>
                  <S.ButtonsWrapper>
                    <Link href="/i4castproduct">
                      <Button outline>{t('more')}</Button>
                    </Link>
                    <a href={formDemoLink} target="_blank" rel="noreferrer">
                      <Button className="getdemo" bigger>
                        {t('cast-platform-talk-button')}
                      </Button>
                    </a>
                  </S.ButtonsWrapper>
                </S.AnimatedContent>
              </S.ComputerWrapper>
            </S.RightSide>
          )}
          {isActive === 'third' && (
            <S.RightSide>
              <S.ComputerWrapper>
                <Image
                  src="/img/p1_01_3x.svg"
                  width={getWidth > 720 ? 580 : 330}
                  height={getWidth > 720 ? 480 : 231}
                  objectFit="contain"
                  alt={t('i4cast-api-alt')}
                />
                <S.AnimatedContent>
                  <S.RightSideDescription>
                    {t('cast-platform-option-3-description')}
                  </S.RightSideDescription>
                  <S.ButtonsWrapper>
                    <Link href="/i4castproduct">
                      <Button outline>{t('more')}</Button>
                    </Link>
                    <a href={formDemoLink} target="_blank" rel="noreferrer">
                      <Button className="getdemo" bigger>
                        {t('cast-platform-talk-button')}
                      </Button>
                    </a>
                  </S.ButtonsWrapper>
                </S.AnimatedContent>
              </S.ComputerWrapper>
            </S.RightSide>
          )}
          {isActive === 'fourth' && (
            <S.RightSide>
              <S.ComputerWrapper>
                <Image
                  src="/img/screen4.png"
                  width={getWidth > 720 ? 580 : 330}
                  height={getWidth > 720 ? 480 : 231}
                  objectFit="contain"
                  alt={t('i4cast-api-alt')}
                />
                <S.AnimatedContent>
                  <S.RightSideDescription>
                    {t('cast-platform-option-4-description')}
                  </S.RightSideDescription>
                  <S.ButtonsWrapper>
                    <Link href="/i4castproduct">
                      <Button outline>{t('more')}</Button>
                    </Link>
                    <a href={formDemoLink} target="_blank" rel="noreferrer">
                      <Button className="getdemo" bigger>
                        {t('cast-platform-talk-button')}
                      </Button>
                    </a>
                  </S.ButtonsWrapper>
                </S.AnimatedContent>
              </S.ComputerWrapper>
            </S.RightSide>
          )}
        </S.Content>
      </S.Wrapper>
    </Container>
  )
}

export default CastPlatform
