import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  @media (max-width: 720px) {
    gap: 3.2rem;
    align-items: start;
  }
`

export const Title = styled.h1`
  display: block;
  font-weight: bold;
  font-size: 3.8rem;
  color: #1e1853;
  text-align: center;
  margin-top: 3rem;
  max-width: 800px;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    line-height: 3rem;
    text-align: start;
  }
`
export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0rem;
  height: 4rem;
  letter-spacing: 0.14rem;
  width: 40rem;
  background: linear-gradient(
    0deg,
    #0b63af 0.05%,
    #147cc0 18.93%,
    #1894d2 37.92%,
    #1fa6df 55.21%,
    #23b2e7 70.02%,
    #25b6ea 80.63%
  );
  border-radius: 0.8rem;
  border: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      0deg,
      #095596 0.05%,
      #116ba6 18.93%,
      #1581b8 37.92%,
      #1b92c4 55.21%,
      #1f9fcf 70.02%,
      #21a3d1 80.63%
    );
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`
